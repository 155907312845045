import { ref, onMounted } from "vue";
import axios from "axios";
export function useKPIData(startDate, endDate, timeSpan) {
    const kpiDataList = ref([]);
    const fetchKPIData = async () => {
        try {
            const data = {
                startDate: startDate.value,
                endDate: endDate.value,
                timeSpan: timeSpan.value,
            };
            const response = await axios.post("/api/v1/kpi", data);
            kpiDataList.value = response.data;
        }
        catch (error) {
            console.error("Failed to fetch KPI data:", error);
        }
    };
    onMounted(fetchKPIData);
    return { kpiDataList, fetchKPIData };
}
