import Index from '../views/Message/Index.vue';
import Threads from '../views/Message/Threads.vue';
const messageRoutes = [
    {
        path: '/messages',
        name: 'MessageIndex',
        component: Index
    },
    {
        path: '/messages/threads',
        name: 'MessageThread',
        component: Threads
    }
];
export default messageRoutes;
