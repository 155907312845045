import axios from "axios";
import { ref } from "vue";
// useAuthStatusは、ログイン状態をAPIに問い合わせて返します。
export function useAuthStatus() {
    const isLoggedIn = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    async function checkAuthStatus() {
        try {
            const response = await axios.post('/api/v1/auth/status');
            isLoggedIn.value = response.data.isLoggedIn;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                // Handle Axios Error
                error.value = e;
            }
            else {
                error.value = new Error('An unexpected error occurred');
            }
            isLoggedIn.value = false;
        }
        finally {
            isLoading.value = false;
        }
    }
    checkAuthStatus();
    return {
        isLoggedIn,
        isLoading,
        error,
        checkAuthStatus,
    };
}
