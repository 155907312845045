export const statusOptions = [
    { text: '応募済', value: 0 },
    { text: '契約済', value: 1 },
    { text: '契約終了', value: 2 },
    { text: '選考中', value: 10 },
    { text: '研修・見学中', value: 20 },
    { text: '選考落', value: 100 },
    { text: '非表示', value: 9999 },
];
export const jobSectionOptions = [
    { text: '新規接客', value: '新規接客' },
    { text: '施行担当', value: '施行担当' },
    { text: 'イベント', value: 'イベント' },
];
