// composablesTypes.ts
export function createInitialPlannerActivityCriteria() {
    const date = new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .split("T")[0];
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0];
    return {
        shiftStartDate: firstDayOfMonth,
        shiftEndDate: lastDayOfMonth,
        hasShiftInput: null,
        shiftCompany: "",
        shiftRequestStartDate: "",
        shiftRequestEndDate: "",
        hasShiftRequest: null,
        plannerRegistrationStartDate: "",
        plannerRegistrationEndDate: "",
        plannerNames: "",
        hasProfileSubmission: null,
        hasContracts: null,
        hasShinkiContracts: null,
        hasUchiawaseContracts: null,
        hasFromAppliedContracts: null,
        contractCompanies: "",
        contractJobs: "",
    };
}
