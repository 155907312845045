export const defaultContractRevision = {
    id: 0,
    createdAt: "",
    updatedAt: "",
    contractId: 0,
    number: 0,
    detail: "",
    successFee: 0,
    serviceFee: 0,
    addWorkFee: 0,
    monthlyFee: 0,
    dailyFee: 0,
    startDate: "",
    jobSection: "",
    guaranteeFee: 0,
    trainingFee: 0,
    hourlyTrainingFee: 0,
    customRewards: [],
    customRewardIds: [],
};
