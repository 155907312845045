import { ref } from "vue";
import axios from "axios";
const messageCompanyUsers = ref([]);
const fetchMessageCompanyUsers = async (companyId, fromUserId) => {
    const params = {
        fromUserId: fromUserId,
        companyId: companyId
    };
    const response = await axios.get("/api/v1/messages/messageCompanyUsers", {
        params: params
    });
    messageCompanyUsers.value = response.data;
};
export default function useMessageCompanyUser() {
    return {
        messageCompanyUsers,
        fetchMessageCompanyUsers
    };
}
