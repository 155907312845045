// useSearchPlanner.ts
import { onMounted, ref } from 'vue';
import { createInitialPlannerActivityCriteria } from './composablesTypes';
import axios from 'axios';
import { objectToQueryString } from './composablesFuncs';
export default function usePlannerActivitySearch() {
    const criteria = ref(createInitialPlannerActivityCriteria());
    const searchResults = ref([]);
    const isLoading = ref(false);
    const error = ref(null);
    // クエリパラメータを読み取って、初期化する
    onMounted(() => {
        const urlParams = new URLSearchParams(window.location.search);
        criteria.value.shiftStartDate = urlParams.get('shiftStartDate') || '';
        criteria.value.shiftEndDate = urlParams.get('shiftEndDate') || '';
        criteria.value.hasShiftInput = urlParams.get('hasShiftInput') === 'true' ? true :
            urlParams.get('hasShiftInput') === 'false' ? false : null;
        criteria.value.shiftCompany = urlParams.get('shiftCompany') || '';
        criteria.value.shiftRequestStartDate = urlParams.get('shiftRequestStartDate') || '';
        criteria.value.shiftRequestEndDate = urlParams.get('shiftRequestEndDate') || '';
        criteria.value.hasShiftRequest = urlParams.get('hasShiftRequest') === 'true' ? true :
            urlParams.get('hasShiftRequest') === 'false' ? false : null;
        criteria.value.plannerRegistrationStartDate = urlParams.get('plannerRegistrationStartDate') || '';
        criteria.value.plannerRegistrationEndDate = urlParams.get('plannerRegistrationEndDate') || '';
        criteria.value.plannerNames = urlParams.get('plannerNames') || '';
        criteria.value.hasProfileSubmission = urlParams.get('hasProfileSubmission') === 'true' ? true :
            urlParams.get('hasProfileSubmission') === 'false' ? false : null;
        criteria.value.hasContracts = urlParams.get('hasContracts') === 'true' ? true :
            urlParams.get('hasContracts') === 'false' ? false : null;
        criteria.value.hasShinkiContracts = urlParams.get('hasShinkiContracts') === 'true' ? true :
            urlParams.get('hasShinkiContracts') === 'false' ? false : null;
        criteria.value.hasUchiawaseContracts = urlParams.get('hasUchiawaseContracts') === 'true' ? true :
            urlParams.get('hasUchiawaseContracts') === 'false' ? false : null;
        criteria.value.hasFromAppliedContracts = urlParams.get('hasFromAppliedContracts') === 'true' ? true :
            urlParams.get('hasFromAppliedContracts') === 'false' ? false : null;
        criteria.value.contractCompanies = urlParams.get('contractCompanies') || '';
        criteria.value.contractJobs = urlParams.get('contractJobs') || '';
    });
    async function fetchData() {
        isLoading.value = true;
        try {
            const response = await axios.post('/api/v1/plannerSearch', criteria.value);
            searchResults.value = response.data;
            const queryString = objectToQueryString(criteria.value);
            const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryString}`;
            window.history.replaceState({}, '', newUrl);
        }
        catch (e) {
            if (e && e.message) {
                error.value = { name: 'Error', message: e.message };
            }
            else {
                error.value = { name: 'Error', message: 'An unknown error occurred.' };
            }
        }
        finally {
            isLoading.value = false;
        }
    }
    ;
    return {
        criteria,
        searchResults,
        isLoading,
        error,
        fetchData
    };
}
