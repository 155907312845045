import Index from "@/views/Contract/Index.vue";
import Create from "@/views/Contract/Create.vue";
import Show from "@/views/Contract/Show.vue";
const contractRoutes = [
    {
        path: "/contract",
        name: "Contracts",
        component: Index,
    },
    {
        path: "/contract/create",
        name: "CreateContract",
        component: Create,
    },
    {
        path: "/contract/:id",
        name: "ContractDetail",
        component: Show,
    },
];
export default contractRoutes;
