import { ref } from "vue";
import axios from "axios";
export default function useJob() {
    const jobs = ref([]);
    const fetchByIds = async (ids) => {
        try {
            let params = "";
            ids.forEach((id) => {
                params += `ids=${id}&`;
            });
            const response = await axios.get("/api/v1/jobs/ids?" + params);
            jobs.value = response.data;
            addSectionAndCompanyToTitle();
        }
        catch (error) {
            console.error(error);
        }
    };
    const fetchAllJob = async () => {
        try {
            const response = await axios.get("/api/v1/jobs");
            jobs.value = response.data;
            addSectionAndCompanyToTitle();
        }
        catch (error) {
            console.error(error);
        }
    };
    const addSectionAndCompanyToTitle = () => {
        if (jobs.value.length === 0) {
            return;
        }
        jobs.value.forEach((job) => {
            job.fullTitle = "【" + job.jobSections + "】" + job.title + ":" + job.companyName;
        });
    };
    return {
        jobs,
        fetchByIds,
        fetchAllJob,
    };
}
