import { defaultUser } from '@/models/user';
import axios from 'axios';
import { ref } from 'vue';
export default function useMessage() {
    const messages = ref([]);
    const fromUser = ref(defaultUser);
    const toUser = ref(defaultUser);
    const latestMessage = ref(null);
    const totalCount = ref(0);
    const criteria = ref({
        page: 1,
        pageSize: 50,
        companyName: '',
        userName: '',
        adminFilter: 'all',
        read: 'all'
    });
    // Fetch messages by user ids
    // fromUserId: number - 0を指定したら、運営ユーザー
    // toUserId: number
    const fetchByUserIds = async (fromUserId, toUserId) => {
        const response = await axios.get('/api/v1/messages', {
            params: {
                fromUserId: fromUserId,
                toUserId: toUserId
            }
        });
        // IDの降順でソート
        messages.value = response.data.messages.sort((a, b) => {
            return a.id - b.id;
        });
        fromUser.value = response.data.fromUser;
        toUser.value = response.data.toUser;
        latestMessage.value = response.data.messages[response.data.messages.length - 1] || null;
    };
    const storeMessages = async (messages) => {
        try {
            await axios.post('/api/v1/messages', messages);
        }
        catch (error) {
            console.error(error);
            window.alert('メッセージの送信に失敗しました。' + error);
            return false;
        }
        return true;
    };
    const searchMessages = async () => {
        const response = await axios.get('/api/v1/messages/search', {
            params: {
                page: criteria.value.page,
                pageSize: criteria.value.pageSize,
                companyName: criteria.value.companyName,
                userName: criteria.value.userName,
                mode: criteria.value.adminFilter,
                read: criteria.value.read,
            }
        });
        totalCount.value = response.headers['x-total-count'];
        messages.value = response.data;
    };
    return {
        messages,
        latestMessage,
        fromUser,
        toUser,
        criteria,
        totalCount,
        fetchByUserIds,
        searchMessages,
        storeMessages
    };
}
