// 新規でメッセージを作成するためのDTOを生成します。
export const createMessageDto = (fromUserId, toUserId, text, attachmentUrl) => {
    return {
        id: 0,
        fromUserId: fromUserId,
        toUserId: toUserId,
        text: text,
        read: false,
        attachmentUrl: attachmentUrl
    };
};
export const defaultMessage = {
    id: 0,
    fromUserId: 0,
    toUserId: 0,
    text: "",
    read: false,
    attachmentUrl: ""
};
