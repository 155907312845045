import axios from "axios";
import { onMounted, ref } from "vue";
export default function useMessageThread() {
    const messages = ref([]);
    const totalCount = ref(0);
    const criteria = ref({
        adminFilter: "all",
        page: 1,
        pageSize: 50
    });
    const fetchThreads = async () => {
        try {
            const response = await axios.get('/api/v1/messages/threads', {
                params: {
                    adminFilter: criteria.value.adminFilter,
                    page: criteria.value.page,
                    pageSize: criteria.value.pageSize
                }
            });
            messages.value = response.data; //.sort((a: Message, b: Message) => {a.sentTime > b.sentTime ? -1 : 1});
            messages.value.sort((a, b) => a.sentTime > b.sentTime ? -1 : 1);
            totalCount.value = parseInt(response.headers["x-total-count"]);
        }
        catch (e) {
            console.error(e);
            window.alert('スレッドの取得に失敗しました。' + e.message);
        }
    };
    onMounted(() => {
        fetchThreads();
    });
    return {
        messages,
        totalCount,
        criteria,
        fetchThreads
    };
}
