import axios from "axios";
import { useRouter } from "vue-router";
export function useLogout() {
    const router = useRouter();
    const logout = async () => {
        try {
            await axios.post('/api/v1/auth/logout');
            router.push('/login');
            window.location.reload();
        }
        catch (error) {
            console.error('Logout error: ', error);
        }
    };
    return {
        logout
    };
}
