// composables/useLogin.ts
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
export function useLogin() {
    const router = useRouter();
    const email = ref('');
    const password = ref('');
    const error = ref(null);
    const login = async () => {
        try {
            const response = await axios.post('/api/v1/auth/login', {
                email: email.value,
                password: password.value
            });
            if (response.status === 200) {
                router.push('/');
                window.location.reload();
            }
            else {
                throw new Error(`Login failed with status: ${response.status}`);
            }
        }
        catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                // Handle Axios Error with response (like invalid credentials)
                error.value = new Error(err.response.data.message || 'An unknown error occurred');
            }
            else {
                // Handle other errors like network issues
                error.value = err;
            }
        }
    };
    return {
        email,
        password,
        error,
        login
    };
}
