// javascript オブジェクトをクエリパラメータの文字列に変換する関数
export const convertCriteria = (criteria) => {
    // オブジェクトを変換して、配列をカンマ区切りの文字列にする
    const params = new URLSearchParams();
    Object.entries(criteria).forEach(([key, value]) => {
        if (value == undefined) {
            // undefined の値は無視
        }
        else if (Array.isArray(value)) {
            // 配列をカンマ区切りの文字列に変換
            if (value.length > 0) {
                params.append(key, value.join(','));
            }
        }
        else {
            // 配列以外の値はそのまま追加
            params.append(key, value.toString());
        }
    });
    return params.toString();
};
export const convertCriteriaForGet = (criteria) => {
    const params = new URLSearchParams();
    Object.entries(criteria).forEach(([key, value]) => {
        if (value == undefined) {
            // undefined の値は無視
        }
        else if (Array.isArray(value)) {
            // 配列をカンマ区切りの文字列に変換
            if (value.length > 0) {
                for (const v of value) {
                    params.append(key, v.toString());
                }
            }
        }
        else {
            // 配列以外の値はそのまま追加
            params.append(key, value.toString());
        }
    });
    return params.toString();
};
