// 新規で契約を作成するためのDTOを生成します。
export const createContractDto = (jobId, plannerId, companyId, status) => {
    return {
        id: 0,
        jobId: jobId,
        plannerId: plannerId,
        companyId: companyId,
        status: status
    };
};
export const createContractDtoFromModel = (contract) => {
    return {
        id: contract.id,
        jobId: contract.jobId,
        plannerId: contract.plannerId,
        companyId: contract.companyId,
        status: contract.status
    };
};
