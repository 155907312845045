import axios from "axios";
import { ref } from "vue";
const superAdminUser = ref(null);
const fetchSuperAdminUser = async () => {
    try {
        const response = await axios.get("/api/v1/superAdminUser");
        superAdminUser.value = response.data;
    }
    catch (error) {
        window.alert("SuperAdminUserの取得に失敗しました");
    }
};
export default function useSuperAdminUser() {
    return { superAdminUser, fetchSuperAdminUser };
}
