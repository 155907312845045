<template>
    <v-list>
      <v-list-item to="/kpi/engagement">
        <v-list-item-title><v-icon class="mr-2">mdi-counter</v-icon>エンゲージメントKPI</v-list-item-title>
      </v-list-item>
      <v-list-item to="/kpi">
        <v-list-item-title><v-icon class="mr-2">mdi-counter</v-icon>詳細KPI</v-list-item-title>
      </v-list-item>
      <v-list-item to="/contract">
        <v-list-item-title><v-icon class="mr-2">mdi-note-text-outline</v-icon>契約一覧</v-list-item-title>
      </v-list-item>
      <v-list-item to="/bulkMessage">
        <v-list-item-title><v-icon class="mr-2">mdi-email-fast-outline</v-icon>メッセージ一斉配信</v-list-item-title>
      </v-list-item>
      <v-list-item to="/messages">
        <v-list-item-title><v-icon class="mr-2">mdi-email-multiple-outline</v-icon>メッセージ一覧</v-list-item-title>
      </v-list-item>
      <v-list-item to="/messages/threads">
        <v-list-item-title><v-icon class="mr-2">mdi-forum</v-icon>スレッド一覧</v-list-item-title>
      </v-list-item>
    </v-list>
</template>

<script lang="ts" setup>
</script>