import axios from "axios";
import { ref } from "vue";
import { convertCriteriaForGet } from "@/utils/convertCriteria";
import router from '@/router';
import { useRoute } from 'vue-router';
// APIエンドポイントを利用して、Contractを操作します。
export default function useContract() {
    // Contractのリストを保持します。
    const contracts = ref([]);
    const totalPages = ref(0);
    const form = ref({
        page: 0,
        pageSize: 100,
        jobSections: "",
        companyName: "",
        plannerName: "",
        jobTitle: "",
        statuses: [],
        jobIds: [],
        plannerIds: [],
    });
    // ContractRequestで指定した条件でContractを取得します。
    const fetchData = async () => {
        try {
            // GetでAPIエンドポイントを呼び出します。
            const params = convertCriteriaForGet(form.value);
            router.push({ query: form.value });
            const response = await axios.get(`/api/v1/contracts?${params}`);
            contracts.value = response.data.map((contract) => {
                return {
                    ...contract,
                    systemNoteCreated: contract.systemNoteCreated
                        ? new Date(contract.systemNoteCreated)
                        : undefined,
                    userNoteCreated: contract.userNoteCreated
                        ? new Date(contract.userNoteCreated)
                        : undefined,
                };
            });
            totalPages.value = Math.ceil(response.headers["x-total-count"] / form.value.pageSize);
            sortById();
        }
        catch (error) {
            window.alert("契約データの取得に失敗しました。");
            contracts.value = [];
        }
    };
    // ContractのIDを指定して、Contractを取得します。
    const fetchByIds = async (ids) => {
        try {
            let params = "";
            ids.forEach((id) => {
                params += `ids=${id}&`;
            });
            // APIエンドポイントを呼び出します。
            const response = await axios.get("/api/v1/contracts/ids?" + params);
            contracts.value = response.data;
            sortById();
        }
        catch (error) {
            console.error(error);
        }
    };
    const sortedContracts = ref([]);
    // Contractsをidの降順でソートします。
    const sortById = () => {
        sortedContracts.value = contracts.value.sort((a, b) => {
            return b.id - a.id;
        });
    };
    const storeContracts = async (contracts) => {
        try {
            // APIエンドポイントを呼び出します。
            await axios.post("/api/v1/contracts", contracts);
            fetchData();
        }
        catch (error) { // 重複で作成した場合のエラー処理
            console.error(error);
            window.alert("契約データの登録に失敗しました。" + error);
            return false;
        }
        return true;
    };
    const updateContracts = async (contracts) => {
        try {
            // APIエンドポイントを呼び出します。
            await axios.put("/api/v1/contracts", contracts);
            fetchData();
        }
        catch (error) {
            throw new Error("契約データの更新に失敗しました。" + error);
        }
    };
    const generateFormFromQuery = () => {
        const route = useRoute();
        const query = route.query;
        // クエリパラメータをformオブジェクトに設定
        form.value.page = query.page ? Number(query.page) : form.value.page;
        form.value.pageSize = query.pageSize ? Number(query.pageSize) : form.value.pageSize;
        form.value.jobSections = query.jobSections || form.value.jobSections;
        form.value.companyName = query.companyName || form.value.companyName;
        form.value.plannerName = query.plannerName || form.value.plannerName;
        form.value.jobTitle = query.jobTitle || form.value.jobTitle;
        form.value.statuses = query.statuses ? (Array.isArray(query.statuses) ? query.statuses.map(Number) : [Number(query.statuses)]) : form.value.statuses;
        form.value.jobIds = query.jobIds ? (Array.isArray(query.jobIds) ? query.jobIds.map(Number) : [Number(query.jobIds)]) : form.value.jobIds;
        form.value.plannerIds = query.plannerIds ? (Array.isArray(query.plannerIds) ? query.plannerIds.map(Number) : [Number(query.plannerIds)]) : form.value.plannerIds;
    };
    return { contracts: sortedContracts, fetchData, fetchByIds, storeContracts, updateContracts, generateFormFromQuery, form, totalPages };
}
