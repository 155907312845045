import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import SearchPlanners from "../views/SearchPlanners.vue";
import KpiTable from "../views/kpi/KpiTable.vue";
import { useAuthStatus } from "@/composables/useAuthStatus";
import authRoutes from "./authRoutes";
import contractRoutes from "./contractRoutes";
import plannerRoutes from "./plannerRoutes";
import messageRoutes from "./messageRoutes";
const routes = [
    ...authRoutes,
    ...contractRoutes,
    ...plannerRoutes,
    ...messageRoutes,
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: "/searchPlanners",
        name: "SearchPLanners",
        component: SearchPlanners,
        meta: { requiresAuth: true },
    },
    {
        path: "/kpi",
        name: "KpiTable",
        component: KpiTable,
        meta: { requiresAuth: true },
    },
    {
        path: "/kpi/engagement",
        name: "EngagementKPI",
        component: () => import("../views/kpi/Engagement.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/invoice",
        name: "Invoice",
        component: () => import("../views/Invoice.vue"),
    },
    {
        path: "/bulkMessage",
        name: "BulkMessage",
        component: () => import("../views/BulkMessage.vue"),
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(async (to, from, next) => {
    // 認証状態をチェック
    const { isLoggedIn, checkAuthStatus } = useAuthStatus();
    await checkAuthStatus(); // 認証状態をAPIに問い合わせ
    if (to.name === "Login" && isLoggedIn.value) {
        // ログインページへのアクセスで既にログインしている場合はホームにリダイレクト
        next({ name: "Home" });
    }
    else if (to.matched.some((record) => record.meta.requiresAuth) &&
        !isLoggedIn.value) {
        // ログインが必要なページへのアクセスでログインしていない場合はログインページにリダイレクト
        next({ name: "Login" });
    }
    else {
        // それ以外の場合は、ナビゲーションをそのまま進める
        next();
    }
});
export default router;
