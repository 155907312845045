export const defaultAdminNote = {
    noteableId: 0,
    noteableType: '',
    content: '',
    isAutoGenerated: false,
};
export var NoteableType;
(function (NoteableType) {
    NoteableType["PlannerMode"] = "Planner";
    NoteableType["CompanyMode"] = "Company";
    NoteableType["ContractMode"] = "Contract";
})(NoteableType || (NoteableType = {}));
