import { ref } from "vue";
import axios from "axios";
export default function useContractRevision() {
    const contractRevisions = ref([]);
    const fetchByContractId = async (contractId) => {
        const response = await axios.get(`/api/v1/contracts/${contractId}/revisions`);
        contractRevisions.value = response.data;
    };
    const upsert = async (contractRevision) => {
        contractRevision.startDate = new Date(contractRevision.startDate).toISOString();
        if (!contractRevision.id) {
            await axios.post("/api/v1/contractRevisions", contractRevision);
        }
        else {
            // CretedAt and UpdatedAt are not updated by the frontend
            contractRevision.createdAt = undefined;
            contractRevision.updatedAt = undefined;
            await axios.put("/api/v1/contractRevisions/" + contractRevision.id, contractRevision);
        }
    };
    return {
        contractRevisions,
        fetchByContractId,
        upsert,
    };
}
