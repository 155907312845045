import axios from "axios";
import { ref } from "vue";
const companyUsers = ref([]);
const companyUser = ref(null);
const fetchCompanyUsersByJobId = async (jobId) => {
    const response = await axios.get(`/api/v1/jobs/${jobId}/companyUsers`);
    companyUsers.value = response.data;
};
const fetchAllCompanyUser = async () => {
    const response = await axios.get('/api/v1/companyUsers');
    companyUsers.value = response.data;
};
export default function useCompanyUser() {
    return { companyUsers, companyUser, fetchCompanyUsersByJobId, fetchAllCompanyUser };
}
