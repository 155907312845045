import Index from '../views/Planner/Index.vue';
import Show from '../views/Planner/Show.vue';
const plannerRoutes = [
    {
        path: '/planners',
        name: 'PlannerIndex',
        component: Index,
    },
    {
        path: '/planners/:id',
        name: 'PlannerShow',
        component: Show,
    },
];
export default plannerRoutes;
