export const defaultContract = {
    id: 0,
    jobTitle: '',
    jobId: 0,
    jobSections: '',
    plannerName: '',
    plannerId: 0,
    companyName: '',
    status: 0,
    statusText: '',
    plannerUserId: 0,
    companyId: 0,
    systemNote: '',
    userNote: '',
};
