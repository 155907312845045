export const defaultUser = {
    id: 0,
    name: 'ユーザー名',
    createdAt: new Date(),
    updatedAt: new Date(),
    isAdmin: false,
    agreement: false,
    hasConfirmation: false,
    isSuperAdmin: false
};
