import { defineComponent } from 'vue';
import { useLogin } from '@/composables/useLogin';
export default defineComponent({
    name: 'LoginComponent',
    setup() {
        const { email, password, error, login } = useLogin();
        const emailRules = [
            (v) => !!v || 'E-mail is required',
            (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ];
        const passwordRules = [
            (v) => !!v || 'Password is required',
            (v) => v.length >= 4 || 'Password must be at least 8 characters'
        ];
        return {
            email,
            password,
            error,
            emailRules,
            passwordRules,
            submit: login
        };
    }
});
