import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.infoArray.length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "popupElement",
            style: _normalizeStyle(_ctx.styleObject),
            class: "popup-window"
        }, [
            _createElementVNode("div", {
                class: "draggable-header",
                onMousedown: _cache[1] || (_cache[1] = _withModifiers(
                //@ts-ignore
                (...args) => (_ctx.dragStart && _ctx.dragStart(...args)), ["stop"])),
                onMouseup: _cache[2] || (_cache[2] = _withModifiers(
                //@ts-ignore
                (...args) => (_ctx.dragEnd && _ctx.dragEnd(...args)), ["stop"]))
            }, [
                _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
                    class: "close-button"
                }, "X")
            ], 32),
            _createElementVNode("table", null, [
                _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoArray[0], (value, key) => {
                            return (_openBlock(), _createElementBlock("th", { key: key }, _toDisplayString(key), 1));
                        }), 128))
                    ])
                ]),
                _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoArray, (item) => {
                        return (_openBlock(), _createElementBlock("tr", { key: item }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (value, key) => {
                                return (_openBlock(), _createElementBlock("td", { key: key }, [
                                    _createElementVNode("span", {
                                        innerHTML: _ctx.convertNewlines(value)
                                    }, null, 8, _hoisted_1)
                                ]));
                            }), 128))
                        ]));
                    }), 128))
                ])
            ])
        ], 4))
        : _createCommentVNode("", true);
}
