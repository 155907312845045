<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6">
                <dl>
                    <!-- シフト入力期間 -->
                    <dt>シフト入力期間</dt>
                    <dd>
                    <date-range-selecter
                        :start-date="criteria.shiftStartDate"
                        :end-date="criteria.shiftEndDate"
                        @update:start-date="criteria.shiftStartDate = $event"
                        @update:end-date="criteria.shiftEndDate = $event"
                    />
                    </dd>
            
                    <!-- シフト入力があるかどうか -->
                    <dt>シフト入力があるかどうか</dt>
                    <dd>
                        <v-radio-group v-mode="criteria.hasShiftInput" inline>
                            <v-radio label="あり" value="true"></v-radio>
                            <v-radio label="なし" value="false"></v-radio>
                            <v-radio label="指定なし" value="null"></v-radio>
                        </v-radio-group>
                    </dd>
            
                    <!-- シフト提出先企業 -->
                    <dt>シフト提出先企業</dt>
                    <dd>
                    <v-text-field label="シフト提出先企業" v-model="criteria.shiftCompany" />
                    </dd>
            
                    <!-- 出勤依頼期間 -->
                    <dt>出勤依頼期間</dt>
                    <dd>
                        <date-range-selecter
                            :start-date="criteria.shiftRequestStartDate"
                            :end-date="criteria.shiftRequestEndDate"
                            @update:start-date="criteria.shiftRequestStartDate = $event"
                            @update:end-date="criteria.shiftRequestEndDate = $event"
                        />
                    </dd>
            
                    <!-- 出勤依頼があるかどうか -->
                    <dt>出勤依頼があるかどうか</dt>
                    <dd>
                        <v-radio-group v-mode="criteria.hasShiftRequest" inline>
                            <v-radio label="あり" value="true"></v-radio>
                            <v-radio label="なし" value="false"></v-radio>
                            <v-radio label="指定なし" value="null"></v-radio>
                        </v-radio-group>
                    </dd>
            
                    <!-- プランナー登録期間 -->
                    <dt>プランナー登録期間</dt>
                    <dd>
                        <date-range-selecter
                            :start-date="criteria.plannerRegistrationStartDate"
                            :end-date="criteria.plannerRegistrationEndDate"
                            @update:start-date="criteria.plannerRegistrationStartDate = $event"
                            @update:end-date="criteria.plannerRegistrationEndDate = $event"
                        />
                    </dd>
            
                    <!-- プランナー名 -->
                    <dt>プランナー名</dt>
                    <dd>
                        <v-text-field v-model="criteria.plannerNames" label="カンマ区切りで複数指定" />
                    </dd>
                    <!-- プロフィール提出済みかどうか -->
                    <dt>プロフィール提出済みかどうか</dt>
                    <dd>
                        <v-radio-group v-mode="criteria.hasProfileSubmission" inline>
                            <v-radio label="あり" value="true"></v-radio>
                            <v-radio label="なし" value="false"></v-radio>
                            <v-radio label="指定なし" value="null"></v-radio>
                        </v-radio-group>
                    </dd>

                    <!-- 契約企業 -->
                    <dt>契約企業</dt>
                    <dd>
                        <v-text-field v-model="criteria.contractCompanies" />
                    </dd>

                    <!-- 契約案件 -->
                    <dt>契約案件</dt>
                    <dd>
                        <v-text-field v-model="criteria.contractJobs" />
                    </dd>
                
                    <!-- 契約が一つ以上あるかどうか -->
                    <dt>契約が一つ以上あるかどうか</dt>
                    <dd>
                        <v-radio-group v-mode="criteria.hasContracts" inline>
                            <v-radio label="あり" value="true"></v-radio>
                            <v-radio label="なし" value="false"></v-radio>
                            <v-radio label="指定なし" value="null"></v-radio>
                        </v-radio-group>
                    </dd>

                    <!-- 新規接客（shinki）の稼働中の契約があるかどうか -->
                    <dt>新規接客（shinki）の稼働中の契約があるかどうか</dt>
                    <dd>
                        <v-radio-group v-mode="criteria.hasShinkiContracts" inline>
                            <v-radio label="あり" value="true"></v-radio>
                            <v-radio label="なし" value="false"></v-radio>
                            <v-radio label="指定なし" value="null"></v-radio>
                        </v-radio-group>
                    </dd>

                    <!-- 施行担当（uchiawase）の稼働中の契約があるかどうか -->
                    <dt>施行担当（uchiawase）の稼働中の契約があるかどうか</dt>
                    <dd>
                        <v-radio-group v-mode="criteria.hasUchiawaseContracts" inline>
                            <v-radio label="あり" value="true"></v-radio>
                            <v-radio label="なし" value="false"></v-radio>
                            <v-radio label="指定なし" value="null"></v-radio>
                        </v-radio-group>
                    </dd>

                    <!-- 応募済みから稼働中の契約があるかどうか -->
                    <dt>応募済みから稼働中の契約があるかどうか</dt>
                    <dd>
                        <v-radio-group v-mode="criteria.hasFromAppliedContracts" inline>
                            <v-radio label="あり" value="true"></v-radio>
                            <v-radio label="なし" value="false"></v-radio>
                            <v-radio label="指定なし" value="null"></v-radio>
                        </v-radio-group>
                    </dd>
                </dl>
                <v-btn @click="fetchData">検索</v-btn>
            </v-col>
        </v-row>
        <div v-if="isLoading">Loading...</div>
        <div v-if="error">{{ error.message }}</div>
        <v-row>
            <v-col>
                <v-table>
                    <thead>
                        <tr>
                            <td>pid</td><td>名前</td><td>シフト登録</td><td>出勤依頼</td><td>出勤報告</td><td>契約</td>
                            <td>新規契約</td><td>施行成約</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="result in searchResults">
                            <td>{{ result.plannerId }}</td>
                            <td>{{ result.plannerName }}</td>
                            <td 
                            v-for="key in ['shifts', 'shiftRequests', 'attendances', 'contracts', 'shinkiContracts', 'uchiawaseContracts']"
                            @click="showPopup(result[key], $event)"
                            :style="{ cursor: result[key].length > 0 ? 'pointer' : 'default' }">
                            {{ result[key].length }}
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>
        <popup-window
            v-if="isShowPopup && showPopupData"
            :info-array="showPopupData"
            :position="popupPosition"
            @close="isShowPopup = false"
        ></popup-window>
    </v-container>
</template>
<script>
    import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
    import usePlannerActivitySearch from '@/composables/useSearchPlanner.ts';
    import DateRangeSelecter from '@/components/DateRangeSelecter.vue';
    import PopupWindow from '@/components/PopupWindow.vue';

    export default defineComponent({
        components: {
            DateRangeSelecter,
            PopupWindow
        },
        setup() {
            const {
                criteria,
                isLoading,
                searchResults,
                error,
                fetchData
            } = usePlannerActivitySearch();

        const showPopupData = ref([]);
        const popupPosition = ref({x: 0, y: 0});
        const popupElement = ref(null); // 追加
        const isShowPopup = ref(false);

        function showPopup(data, event) {
            isShowPopup.value = true;
            showPopupData.value = data;
            popupPosition.value.x = event.clientX + window.scrollX + 10;
            popupPosition.value.y = event.clientY + window.scrollY + 10;
        }

        function closePopupIfClickedOutside(event) {
            if (popupElement.value && !popupElement.value.contains(event.target)) {
                showPopupData.value = [];
            }
        }

        onMounted(() => {
            window.addEventListener('mousedown', closePopupIfClickedOutside);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('mousedown', closePopupIfClickedOutside);
        });

            return {
                criteria,
                searchResults,
                isLoading,
                error,
                fetchData,
                showPopup,
                showPopupData,
                popupPosition,
                popupElement,
                isShowPopup,
            };
        }
    });
</script>