import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'; // Material Design Icons
//import 'vuetify/lib/styles/main.sass'; // Vuetify styles
import * as components from 'vuetify/components';
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi.mjs';
const vuetify = createVuetify({
    components: {
        ...components
    },
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                colors: {
                    primary: "#3f51b5",
                    secondary: "#607d8b",
                    accent: "#ffeb3b",
                    error: "#f44336",
                    warning: "#9c27b0",
                    info: "#ff9800",
                    success: "#4caf50",
                },
            },
        },
    },
});
export default vuetify;
