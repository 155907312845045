import { defineComponent, ref } from "vue";
import { useKPIData } from "@/composables/kpi/useKPIData";
import DateRangeSelecter from "@/components/DateRangeSelecter.vue";
export default defineComponent({
    components: {
        DateRangeSelecter,
    },
    setup() {
        const startDate = ref(new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1)
            .toLocaleDateString("ja-JP", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
            .replaceAll("/", "-"));
        const endDate = ref(new Date(new Date().getFullYear(), new Date().getMonth(), 0)
            .toLocaleDateString("ja-JP", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
            .replaceAll("/", "-"));
        const timeSpan = ref("monthly");
        const { kpiDataList, fetchKPIData } = useKPIData(startDate, endDate, timeSpan);
        return { kpiDataList, fetchKPIData, startDate, endDate, timeSpan };
    },
});
