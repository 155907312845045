import axios from "axios";
import { ref } from "vue";
export default function useCustomReward() {
    const customRewards = ref([]);
    const fetchByCompanyId = async (companyId) => {
        const response = await axios.get(`/api/v1/companies/${companyId}/customRewards`);
        customRewards.value = response.data;
    };
    return { customRewards, fetchByCompanyId };
}
