import axios from "axios";
import { computed, ref, watch } from "vue";
export default function useAdminNotes(mode, noteableId, noteableType) {
    const rawNotes = ref([]);
    const fetchByNoteableId = async () => {
        const params = {
            noteableId: noteableId.value,
            noteableType: noteableType.value,
        };
        try {
            const response = await axios.get(`/api/v1/adminNotes`, { params });
            if (response.data == null) {
                rawNotes.value = [];
                return;
            }
            rawNotes.value = response.data;
        }
        catch (error) {
            window.alert(error.message);
        }
    };
    // notes はmodeで指定されたものだけを返す
    const notes = computed(() => {
        return rawNotes.value.filter((note) => {
            if (mode.value === undefined)
                return true;
            if (mode.value === 'system')
                return note.isAutoGenerated;
            if (mode.value === 'user')
                return !note.isAutoGenerated;
            if (mode.value === 'all')
                return true;
            return true;
        }).sort((a, b) => {
            const ac = a.createdAt ?? 0;
            const bc = b.createdAt ?? 0;
            return ac < bc ? 1 : -1;
        });
    });
    const store = async (data) => {
        try {
            await axios.post(`/api/v1/adminNotes`, data);
        }
        catch (error) {
            window.alert(error.response.data.message || error.message);
            return false;
        }
        return true;
    };
    const updateContent = async (id, content) => {
        try {
            await axios.put(`/api/v1/adminNotes/${id}`, { content });
        }
        catch (error) {
            window.alert(error.response.data.message || error.message);
            return false;
        }
        return true;
    };
    const deleteById = async (id) => {
        try {
            await axios.delete(`/api/v1/adminNotes/${id}`);
        }
        catch (error) {
            window.alert(error.response.data.message || error.message);
        }
    };
    watch([mode, noteableId, noteableType], async () => {
        console.log(`changed ${mode.value} ${noteableId.value} ${noteableType.value}`);
        await fetchByNoteableId();
    }, { immediate: true });
    return { notes, fetchByNoteableId, store, updateContent, deleteById };
}
