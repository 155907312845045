// カスタムシリアライズ関数を定義します。配列を含むクエリパラメータをGoで正しく読み込めるようシリアライズするために使用します。
export const paramsSerializer = (params) => {
    return Object.keys(params)
        .map((key) => {
        const value = params[key];
        if (Array.isArray(value)) {
            // 配列の各要素に対して、`key=value`の形式でクエリを生成します。
            return value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(String(item))}`).join('&');
        }
        else {
            // 配列でない場合は、通常通り`key=value`の形式で返します。
            return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
        }
    })
        .join('&');
};
export const formatDate = (date) => {
    if (date === undefined)
        return '';
    if (!(date instanceof Date))
        return '';
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
};
export const formatShortDate = (date) => {
    if (date === undefined)
        return '';
    if (!(date instanceof Date))
        return '';
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
};
