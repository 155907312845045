import { ref } from "vue";
import axios from "axios";
import { paramsSerializer } from "@/utils/utils";
export default function usePlanner() {
    const planners = ref([]);
    const planner = ref(null);
    const criteria = ref({
        page: 0,
        pageSize: 100,
        name: "",
        plannerStatuses: [],
        contractCompanyIds: [],
        contractJobIds: [],
        contractStatuses: [],
    });
    const totalPage = ref(0);
    const fetchByIds = async (ids) => {
        try {
            let params = "";
            ids.forEach((id) => {
                params += `ids=${id}&`;
            });
            const response = await axios.get("/api/v1/planners/ids?" + params);
            planners.value = response.data;
        }
        catch (error) {
            console.error(error);
        }
    };
    const fetchAllPlanner = async () => {
        try {
            const response = await axios.get("/api/v1/planners");
            planners.value = response.data;
        }
        catch (error) {
            console.error(error);
        }
    };
    const searchPlanner = async () => {
        try {
            const response = await axios.get("/api/v1/planners", {
                params: {
                    ...criteria.value,
                },
                paramsSerializer: paramsSerializer,
            });
            planners.value = response.data;
            totalPage.value = Math.ceil(response.headers["x-total-count"] / criteria.value.pageSize);
        }
        catch (error) {
            console.error(error);
        }
    };
    const fetchById = async (id) => {
        try {
            const response = await axios.get(`/api/v1/planners/${id}`);
            planner.value = response.data;
        }
        catch (error) {
            window.alert("プランナーの取得に失敗しました。" + error);
            console.error(error);
        }
    };
    return {
        planner,
        planners,
        criteria,
        totalPage,
        fetchByIds,
        fetchById,
        fetchAllPlanner,
        searchPlanner,
    };
}
