import { ref, toRefs, computed, onMounted, defineComponent, onBeforeUnmount } from 'vue';
export default defineComponent({
    props: {
        infoArray: {
            type: Array,
            required: true,
        },
        position: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { infoArray, position } = toRefs(props);
        const dragging = ref(false);
        const dragStart = (event) => {
            dragging.value = true;
            window.addEventListener('mouseup', dragEnd);
        };
        const dragEnd = (event) => {
            dragging.value = false;
            window.removeEventListener('mouseup', dragEnd);
        };
        const convertNewlines = (value) => {
            return String(value).replace(/\n/g, '<br>');
        };
        const closePopup = () => {
            emit('close');
        };
        const styleObject = computed(() => {
            return {
                top: `${position.value.y}px`,
                left: `${position.value.x}px`,
                'min-width': '300px'
            };
        });
        const mouseMoveHandler = (event) => {
            if (dragging.value) {
                position.value.x += event.movementX;
                position.value.y += event.movementY;
            }
        };
        onMounted(() => {
            window.addEventListener('mousemove', mouseMoveHandler);
        });
        onBeforeUnmount(() => {
            window.removeEventListener('mousemove', mouseMoveHandler);
        });
        return {
            dragStart,
            dragEnd,
            convertNewlines,
            closePopup,
            styleObject,
            infoArray,
            position,
        };
    }
});
